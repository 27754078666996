import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/data/api.service';
import { AuthService } from 'src/app/shared/keycloak.auth.service';
import { MajCguBody } from 'src/app/shared/models/models';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ENDPOINT } from '../../../constants/endpoints';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-cgu',
  templateUrl: './modal-cgu.component.html',
  styleUrls: ['./modal-cgu.component.scss'],
})
export class ModalCguComponent implements OnInit {
  public isLoading = false;
  public data: any = null;
  public displayCgu = false;
  public cguChecked = false;

  constructor(
    public modalRef: BsModalRef,
    public bsModalRef: BsModalRef,
    private keycloakService: KeycloakService,
    private authService: AuthService,
    private apiService: ApiService,
    private notifications: NotificationsService
  ) {}

  ngOnInit(): void {}

  public onClose(): void {
    if (!this.cguChecked) {
      this.keycloakService.logout(window.location.href);
    } else {
      if (this.authService.currentUser.roles[0] === 'employeur') {

      } else {
        const majCguAdhBody: MajCguBody = {
          Date_accept_cgu: parseInt(moment().format('YYYYMMDD'), 10)
        };
        this.apiService.post('mpla', ENDPOINT.majcguadh.path, majCguAdhBody).subscribe(
          (res) => {
            this.notifications.create(
              'Succès',
              `Les CGU ont bien été acceptées`,
              NotificationType.Success,
              { theClass: 'outline success', timeOut: 3000, showProgressBar: true }
            );
          },
          (err) => {
            if (err.error.Libelle_retour) {
              this.notifications.create(
                'Erreur',
                err.error.Libelle_retour,
                NotificationType.Error,
                { theClass: 'outline danger', timeOut: 3000, showProgressBar: true }
              );
            }
          }
        );
      }
    }
    this.modalRef.hide();
  }

  public onChange(checked): void {
    this.cguChecked = checked;
  }
}

