export class Response {
  'Code_retour': string;
  'Libelle_retour': string;
}

export interface PaginatedResponse extends Response {
  records: any[];
  total_record: number;
  total_page: number;
  page_size: number;
  page: number;
  next_page: number;
  prev_page: number;
}

// WS032 (GET) : SW Liste des CP adresses
export interface ListeCpAdresseResponse extends Response {
  Liste_cpadr: CpAdresse[];
}
export interface CpAdresse {
  Code_postal_adr: string;
  Commune_adr: string;
  Pays_adr: string;
}
// WS133 (GET) : Liste des codes postaux des BP
export interface ListeCpBpResponse extends Response {
  Liste_cpbp: CpBp[];
}
export interface CpBp {
  Code_postal_bp: string;
  Commune_bp: string;
  Debut_tranche: string;
  Fin_tranche: string;
  Liste_bp?: string[];
}

export interface MailEntity {
  from: string;
  to: string[];
  cc?: string[];
  bcc?: string[];
  subject?: string;
  message?: string;
  password?: string;
  attachments?: Attachment[];
  embedded?: Attachment[];
  tags?: string[];
  html: boolean;
}
export interface Attachment {
  content: string;
  filename: string;
}

// WS006 (POST) : mot de passe oublié, changement du mot de passe
export type InfoCguResponse = Response & {
  Top_cgu_accept: string;
  Nom_version_accept: string;
};
// WS007 (POST) : modification des CGU, mise à jour date acceptation (adhérent)
// WS107 (POST) : modification des CGU, mise à jour date acceptation (employeur)
export type MajCguResponse = Response & {};
export interface MajCguBody {
  Date_accept_cgu: number;
}

export interface MemberType {
  name: string;
  tid: string;
  description_value: string;
  view_taxonomy_term: string;
  articles?: Article[];
}
export interface Article {
  title: string;
  body: string;
  field_i_news: string;
  field_member_type: string;
  field_chapo: string;
  field_contenu: string;
  field_i_news_date_publication: string;
  field_i_news_image: string;
  nid: string;
  body_1: string;
  view_node: string;
}
export interface PaginatedArticlesResponse extends PaginatedResponse {
  records: Article[];
}

// WS020 (GET) : Récupération informations personnelles d’un adhérent
// WS120 (GET) : Récupération informations personnelles d’un employeur
export interface InfosResponse extends Response {
  Nom_societe: string;
  Type_contrat_sante: string;
  Date_debut_contrat_sante: number;
  Courriel_principal: string;
  Courriel_complement: string;
  Type_contrat_prev: string;
  Nom_contrat_prev: string;
  Date_debut_contrat_prev: number;
  Date_procuration : number;
  Adresse_1: string;
  Adresse_2: string;
  Adresse_3: string;
  Adresse_4: string;
  Adresse_5: string;
  Tel_portable: number;
  Top_info_commercial: string; // O = oui // N = non // B = non encore précisé // A la 1 ère connexion, l’assuré est obligé de mettre oui ou non.
  Top_news_letters: string; // Ne sert pas pour l’instant
}
// WS020 (GET) : Récupération informations personnelles d’un adhérent
export interface InfosAdherentResponse extends InfosResponse, TelephonesAdherentBody {

  Num_cafat: number;
  Nom: string;
  Prenom: string;
  Date_naissance: number;
  Date_debut_droit: number;
  Date_fin_droit: number;
  Date_fin_adhesion: number;
  Date_procuration : number;
  Statut_adherent: string;
  Libelle_adherent: string | string[];
  Adresse_nom_dest: string;
  Tel_assure1: number;
  Tel_assure2: number;
  contratClass?: string;
  contratLabel?: string;
  contratLabel2?: string;
  contratLabel3?: string;
  Type_contrat_sante2: string;
  Date_debut_contrat_sante2: number;
  Type_contrat_sante3: string;
  Date_debut_contrat_sante3: number;
  Nom_societe:string;
  Pays:string;
}
// WS120 (GET) : Récupération informations personnelles d’un employeur
export interface InfosEmployeurResponse extends InfosResponse, TelephonesEmployeurBody {
  Num_employeur: number;
  Adresse_nom_emp: string;
  Tel_travail1: number;
  Tel_travail2: number;
  Date_debut_adhes: number;
  Date_fin_drt: number;
  Date_fin_adhes: number;
  Nbr_adherent: number;
  Nbr_ayant_droit: number;
  Montant_cotis: number;
  contratClass?: string;
  contratLabel?: string;
}
// WS038(GET) : récupération de la liste des mutuelles d'un bénéficiaire
export interface ListeBenefMutuelles extends Response{
  Liste_mut_benef: MutBenef[];
}

export interface MutBenef {
  Nom_mut:string;
}

// WS034 (GET) : récupération de l’adresse de correspondance détaillée d’un adhérent
export interface AdresseCorrespondanceAdherent {
  Ligne_co: string;
  Appartbatim: string;
  Residencetribu: string;
  Num_rue: string;
  Rue: string;
  Cp: string;
  Bp: string;
  Code_retour:string;
  Libelle_retour:string;
  Pays:string;
}

// WS039(GET) : récupération de la liste des forfaits soins d'un bénéficiare
export interface ListeForfaitBenef extends Response{
  Contrat: string,
  Date_deb_per: number,
  Date_fin_per : number,
  Liste_forfait_benef: ForfaitBenef[]
}

export interface ForfaitBenef{
  Mt_plafond : number;
  Nb_acte_paye : number;
  Mt_paye : number;
  Nb_acte_attente : number;
  Mt_attente : number;
}
// WS040 (GET) : Liste des pays
export interface ListePays extends Response  {
  Liste_pays: ListePays[];
}

export interface ListePays{
  Nom_pays:string;
}

// WS128 (GET) : récupération de l’adresse de correspondance détaillée d’un employeur
export type AdresseCorrespondanceResponse = Response & AdresseCorrespondanceBody & {};
// WS022 (POST) : Modification de l’adresse de correspondance d’un adhérent
export interface MajAdresseResponseAdherent extends Response  {
  Ligne_co: string;
  Appartbatim: string;
  Residencetribu: string;
  Num_rue: string;
  Rue: string;
  Cp: string;
  Bp: string;
  Pays: string;
};
// WS122 (POST) : Modification de l’adresse de correspondance d’un employeur
export type MajAdresseResponse = Response & {};
export interface AdresseCorrespondanceBody {
  Ligne_co: string;
  Appartbatim: string;
  Residencetribu: string;
  Num_rue: string;
  Rue: string;
  Cp: string;
  Bp: string;
  Pays: string;
}

// WS021 (POST) : Modification des n° de téléphone d’un adhérent
// WS121 (POST) : Modification des n° de téléphone d’un employeur
export type TelephonesResponse = Response & {};
export interface TelephonesBody {
  Tel_portable: number;
}
// WS021 (POST) : Modification des n° de téléphone d’un adhérent
export interface TelephonesAdherentBody extends TelephonesBody {
  Tel_assure1: number;
  Tel_assure2: number;
}
// WS121 (POST) : Modification des n° de téléphone d’un employeur
export interface TelephonesEmployeurBody extends TelephonesBody {
  Tel_travail1: number;
  Tel_travail2: number;
}

// WS023 (POST) : Modification de l’adresse courriel complémentaire d’un adhérent
// WS123 (POST) : Modification de l’adresse courriel complémentaire d’un employeur
export type MajCourrielCompResponse = Response & {};
export interface MajCourrielCompBody {
  Courriel_complement: string;
}

// WS024 (POST) : Modification acceptation commerciale
// WS124 (POST) : Modification acceptation commerciale
export type MajAcceptCommResponse = Response & {};
export interface MajAcceptCommBody {
  Clause_commerciale: string;
}


// WS125 (GET) : Liste des bénéficiaires de l’adhésion employeur
export interface ListeBenefResponse extends Response {
  Liste_benef: ListeBenefAdherent[] | ListeBenefEmployeur[] | BenefEmployeurAdh[];
}
// WS025 (GET) : Liste des bénéficiaires de l’adhésion adhérent
export interface ListeBenefAdherent {
  Num_cafat_benef: number;
  Nom: string;
  Prenom: string;
  Date_naissance: number;
  Date_debut_adh: number;
  Date_deb_contrat: number;
  Date_fin_droit: number;
  Date_fin_adhesion: number;
  Date_procuration: number;
  Type_contrat: string;
  Type_benef: string;
  Liste_mutuelles?: string;
  Message_info1 : string;
  Date_deb_contrat2: number;
  Type_contrat2: string;
  Date_deb_contrat3: number;
  Type_contrat3: string;
  ///////////////////////////
  
  contratClass?: string;
  contratLabel?: string;
  expanded?: boolean;
}
// WS125 (GET) : Liste des bénéficiaires de l’adhésion employeur
export interface ListeBenefEmployeur {
  Num_famille: number;
  Type_benef: string; // ADHERENT ou AYANT-DROIT
  Num_cafat_adher: number;
  Nom_prenom_adher: string;
  Num_cafat_ayd: number;
  Nom_prenom_ayd: string;
  Date_naissance: number;
  Date_debut_adh: number;
  Date_debut_droit: number;
  Date_fin_droit: number;
  Date_fin_adh: number;
  Date_procuration: number;
  Message_informatif : string;
  ///////////////////////////
  contratClass?: string;
  contratLabel?: string;
  ayd?: ListeBenefEmployeur[];
  expanded?: boolean;
}
export interface BenefEmployeurAdh {
  Num_famille: number;
  Type_benef: string; // ADHERENT ou AYANT-DROIT
  Num_cafat_adher: number;
  Nom_prenom_adher: string;
  Date_naissance: number;
  Date_debut_adh: number;
  Date_debut_droit: number;
  Date_fin_droit: number;
  Date_fin_adh: number;
  Date_procuration: number;  
  Message_informatif : string;
  ///////////////////////////
  contratClass?: string;
  contratLabel?: string;
  ayd: BenefEmployeurAyd[];
  expanded?: boolean;
}
export interface BenefEmployeurAyd {
  Num_famille: number;
  Type_benef: string; // ADHERENT ou AYANT-DROIT
  Num_cafat_ayd: number;
  Nom_prenom_ayd: string;
  Date_naissance: number;
  Date_debut_adh: number;
  Date_debut_droit: number;
  Date_fin_droit: number;
  Date_fin_adh: number;
  Date_procuration: number;
  Message_informatif : string;
  ///////////////////////////
  contratClass?: string;
  contratLabel?: string;
}
export interface ListeBenefEmployeurAdhAyd extends Response {
  Liste_benef: BenefEmployeurAdh[];
}
// SORT & FILTER FOR LISTEBENEF EMP
export interface SortItem {
  id: number;
  label: string;
  value: string;
  type: number;
}
export interface FilterItem {
  nom: string;
  numCafat: number;
  all: boolean;
  adh: boolean;
  ayd: boolean;
}

// WS050 (GET) : Téléchargement d’une attestation familiale de l’adhérent
export interface AttestFamilleResponse extends Response {
  Attestation_famille: Blob | File | string;
}
// WS051 (GET) : Téléchargement d’une attestation d’adhésion d’un bénéficiaire
export interface AttestBenefResponse extends Response {
  Attestation_benef: Blob | File | string;
}
// WS057 (GET) : Téléchargement d’un certificat de radiation d'un bénéficiaire
export interface CertifRadiationBenefResponse extends Response {
  Certificatrad_benef: Blob | File | string;
}
export interface AttestBenefBody {
  Num_cafat_benef: string;
}

// WS026 (GET) : Informations de paiement de cotisations d’un adhérent
// WS126 (GET) : Informations de paiement de cotisations d’un employeur
export interface InfoPaieCotisResponse extends Response {
  Mode_paiement: string;
  Periodicite_paiement: string;
  Lib_compte_prelev: string;
  Banque_compte_prelev: string;
  Guichet_compte_prelev: number;
  Numero_compte_prelev: string;
  Code_banque_compte_prelev: string;
  Cle_compte_prelev: string;
}

// WS027 (GET) : Récupération de l’historique des mouvements de cotisations
// WS127 (GET) : Récupération de l’historique des mouvements de cotisations
export interface HistoMvtCotisResponse extends Response {
  Solde?: number;
  Mouvement: MvtCotis[];
}
export interface MvtCotis {
  Libelle_operation: string;
  Montant_cotis: number;
}

// WS052 (GET) : Téléchargement d’une attestation de déductibilité fiscale
export interface AttestFiscResponse extends Response {
  Attestation_fisc: Blob | File | string;
}

// WS053 (GET) : Téléchargement de l’appel de cotisation récapitulatif
export interface ACRecapAdhResponse extends Response {
  Ac_recap_adh: Blob | File | string;
}
// WS153 (GET) : Téléchargement de l’appel de cotisation récapitulatif
export interface ACRecapEmpResponse extends Response {
  Ac_recap_emp: Blob | File | string;
}

// WS054 (GET) : Téléchargement de l’appel de cotisation détaillé
export interface ACDetailAdhResponse extends Response {
  Ac_detail_adh: Blob | File | string;
}
// WS154 (GET) : Téléchargement de l’appel de cotisation détaillé
export interface ACDetailEmpResponse extends Response {
  Ac_detail_emp: Blob | File | string;
}

// WS055 (GET) : Téléchargement de la situation de compte
// WS155 (GET) : Téléchargement de la situation de compte
export interface SitCompteResponse extends Response {
  Sit_compte: Blob | File | string;
}

// WS156 (GET) : Téléchargement du fichier .csv des cotisations d’un employeur
export interface FicCotisResponse extends Response {
  Fic_cotis: Blob | File | string;
}

// WS028 (GET) : Informations bancaire de remboursement de prestations d’un adhérent
export interface InfoPaiePrestaResponse extends Response {
  Mode_remb: string;
  Lib_compte_remb: string;
  Banque_compte_remb: string;
  Code_banque_compte_remb: number;
  Guichet_compte_remb: number;
  Numero_compte_remb: string;
  Cle_compte_remb: string;
}

// WS029 (GET) : Historique remboursements de prestations
export interface HistoMvtPrestaResponse extends Response {
  Mouvement: MvtPresta[];
}
export interface MvtPresta {
  Libelle_mvt: string;
  Date_mvt: number;
  Montant_mvt: number;
  Numero_mvt: number;
  ////////////////////////////
  Factures?: Facture[];
  height?: number;
  selected?: boolean;
}

// WS030 (GET) : Détails des factures d’un règlement de prestation
export interface DetailFactureResponse extends Response {
  Facture: Facture[];
}
export interface Facture {
  Numero_facture: number;
  Date_facture: number;
  Num_cafat_Soins: number;
  Nom_beneficiaire: string;
  Total_facture: number;
  Part_cafat: number;
  Part_mutuelle: number;
  Part_tiers: number;
  Part_assure: number;
  Code_professionnel: number;
  Nom_professionnel: string;
  Message_com:string;
  ////////////////////////////
  Actes?: Acte[];
}

// WS031 (GET) : Détail d’une facture de prestation
export interface ListeActeResponse extends Response {
  Acte: Acte[];
}
export interface Acte {
  Date_acte: number;
  Code_acte: string;
  Quantite: number;
  Coefficient: number;
  Base_remb: number;
  Montant_depense: number;
  Taux_cafat: number;
  Montant_cafat: number;
  Taux_mutuelle: number;
  Montant_mutuelle: number;
  Taux_tp: number;
  Montant_tp: number;
  Montant_assure: number;
  Montant_autre_caisse: number;
  Code_etab: number;
  Nom_etab: string;
  Code_exec: number;
  Nom_exec: string;
  Contrat: string;
  Montant_depense_dev: number;
  Devise: string;
}

// WS058 (GET) : Téléchargement décompte de prestation
export interface DecompteResponse extends Response {
  Attestation_benef: any;
  Decompte: Blob | File | string;
}
