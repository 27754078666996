import { HttpClient } from '@angular/common/http';
import { Injectable,EventEmitter } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed } from 'keycloak-js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { tap } from 'rxjs/operators';
import { ENDPOINT } from '../constants/endpoints';
import { UserRole } from './auth.roles';
import { ModalCguComponent } from '../views/app/modal-cgu/modal-cgu.component';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public onDataReady: EventEmitter<object> = new EventEmitter<object>();

    public userRoles = ['assure_salarie', 'employeur', 'assure_volontaire', 'assure_retraite'];
    public userStatusRoles = {
        SALARIE: UserRole.Salarie,
        EMPLOYEUR: UserRole.Employeur,
        VOLONTAIRE: UserRole.Volontaire,
        RETRAITE: UserRole.Retraite
    };
    public currentUser: any = null;
    public notificationErrorOptions = { timeOut: 3000, showProgressBar: true };
    public bsModalRef: BsModalRef;

    constructor(
        private keycloakService: KeycloakService,
        private http: HttpClient,
        private notifications: NotificationsService,
        private modalService: BsModalService
    ) { }

    public updateData(data:object) {
        // Appeler la méthode emit pour émettre l'événement onDataReady avec les données mises à jour.
        this.onDataReady.emit(data);
    }

    public async getLoggerUser(): Promise<KeycloakTokenParsed | undefined> {
        if (this.currentUser && this.keycloakService.isLoggedIn) {
            return this.currentUser;
        }
        const api = 'mpla'
        localStorage.removeItem('infosperso');
        localStorage.setItem('apiPath', api);
        localStorage.setItem('appPath', 'adherent' );
        try {
            if (this.keycloakService.isTokenExpired()) {
                this.currentUser = null;
                return null;
            }
            const userDetails: KeycloakTokenParsed | undefined = { ...this.keycloakService.getKeycloakInstance().idTokenParsed };
            if (userDetails) {
                if (api === 'mpla' && userDetails.roles && userDetails.roles.length > 0 && !userDetails.roles.some((r) => this.userRoles.includes(r))) {
                    const adh: any = await this.http.get(`/api/mpla/ws/${ENDPOINT.infopersoadherent.path}`)
                        .pipe(tap((value) => {
                            if (value) {
                                localStorage.setItem('infosperso', JSON.stringify(value));
                                this.onDataReady.emit(value);
                            }
                        }))
                        .toPromise().catch((e) => {
                            console.log(e);
                            if (e?.error?.Libelle_retour) {
                                this.notifications.create('Erreur', e.error.Libelle_retour, NotificationType.Error, this.notificationErrorOptions);
                            }
                            localStorage.setItem('infospersoError', JSON.stringify(e));
                        });
                    if (adh) {
                        userDetails.roles = [this.userStatusRoles[adh.Statut_adherent]];
                        localStorage.setItem('statutAdherent', JSON.stringify(userDetails.roles[0]))
                    }
                }
            }
            this.currentUser = userDetails;
            return userDetails;
        } catch (e) {
            console.error('Exception', e);
            localStorage.setItem('loggedUserError', JSON.stringify(e));
            this.notifications.create('Erreur', `Erreur lors de la connexion, veuillez contacter la Mutuelle des Patentés et Libéraux`, NotificationType.Error, this.notificationErrorOptions);
            return undefined;
        }
    }

    openModalCgu(userDetails, api): void {
        this.bsModalRef = this.modalService.show(ModalCguComponent, {
            class: 'modal-cgu modal-lg',
            backdrop: 'static',
            keyboard: false,
        });
        this.bsModalRef.content.data = { userDetails, apiPath: api };
    }

    public isLoggedIn(): Promise<boolean> {
        return this.keycloakService.isLoggedIn();
    }

    public loadUserProfile(): Promise<KeycloakProfile> {
        return this.keycloakService.loadUserProfile();
    }

    public login(): void {
        this.keycloakService.login();
    }

    public logout(): void {
        const redirectUri = window.location.href.includes('employeur') ? `${window.location.origin}/employeur` : `${window.location.origin}/adherent`;
        window.localStorage.clear();
        window.location.replace('https://connect-dev.gouv.nc/');
        this.keycloakService.logout(redirectUri).then(
            (res) => {
                localStorage.removeItem('firstConnect');
                localStorage.removeItem('loggedUser');
                localStorage.removeItem('infopersoadherent');
                localStorage.removeItem('infosperso');
                localStorage.removeItem('apiPath');
                localStorage.removeItem('appPath');
                localStorage.removeItem('token');
                localStorage.removeItem('jti');
                localStorage.removeItem('idToken');
                localStorage.removeItem('KEYCLOAK_SESSION');
                localStorage.removeItem('id_token_hint');
                localStorage.removeItem('id_token');
                this.keycloakService.logout();
                this.currentUser = null;
                this.keycloakService.getToken = null;

            }
        ).catch((e) => {
            console.error(e);
        });
    }

    public redirectToProfile(): void {
        this.keycloakService.getKeycloakInstance().accountManagement();
    }

    public async getRoles(api: string = 'mpla'): Promise<string[]> {
        let roles = this.keycloakService.getUserRoles();
        if (roles.length > 0 && !roles.some((role: string) => this.userRoles.includes(role))) {
            const endpoint = ENDPOINT.infopersoadherent.path;
            const request: any = await this.http.get(`/mpla/ws/${endpoint}`)
                .pipe(tap((value) => { if (value) { localStorage.setItem('infosperso', JSON.stringify(value)); } }))
                .toPromise();
            if (request) {
                roles = [this.userStatusRoles[request.Statut_adherent]];
            }
        }
        return roles;
    }
}
