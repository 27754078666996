import { Component, OnInit,TemplateRef, Renderer2, OnDestroy,ViewChild ,AfterContentInit,ChangeDetectorRef,Injectable,ElementRef } from '@angular/core';
import { LangService } from './shared/lang.service';
import { NgcCookieConsentService,NgcNoCookieLawEvent,NgcInitializeEvent,NgcStatusChangeEvent} from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { KeycloakTokenParsed } from 'keycloak-js';
import { KeycloakService } from 'keycloak-angular';
import { ModalDirective,BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationsService } from 'angular2-notifications';
import { User } from './shared/auth.model';
import { AuthService } from './shared/keycloak.auth.service';
import { UserRole } from './shared/auth.roles';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
@Injectable()
export class AppComponent implements OnInit, AfterContentInit,OnDestroy {
  @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;
  // isMultiColorActive = environment.isMultiColorActive;
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  private changeDetectorRef : ChangeDetectorRef;
  isModalShown = false;
  public firstConnect='';
  messages: string[] = [];
  modalRef?: BsModalRef;
  public currentUser: User = null;
  static parameters = [BsModalService];
  public infosStorage=[];
  public statutAdherent:string;
  public user: any = null;
  public userRole = UserRole;
  public userInfos: any[];
  public numberOfCards:string;

  constructor(
    private notifications: NotificationsService,
    private modalService: BsModalService,
    private keycloakService: KeycloakService,
    private langService: LangService,
    private renderer: Renderer2,
    private ccService: NgcCookieConsentService,
    private authService: AuthService,
    private elementRef: ElementRef)
    {}

    openModal(templateModal: TemplateRef<any>) {
      this.modalRef = this.modalService.show(templateModal);
    }

    public  isRetraite():boolean{
     return this.userInfos['Statut_adherent']==='RETRAITE';
    }

   ngAfterContentInit(): void {
    //charge les infos de l'utilisateur
    this.authService.onDataReady.subscribe(
      (data: object[]) => {
        setTimeout(() => {
          this.renderer.addClass(document.body, 'show');
          const cardElements = this.elementRef.nativeElement.querySelectorAll('.card-mod');
          this.numberOfCards = cardElements.length;//récupère le nombre de cards-bootstrap de class 'card-mod'
          }, 100);
        var formatData=JSON.stringify(data);
        this.userInfos = JSON.parse(formatData);//récupere les infos de l'utilisateur
       
        this.infosStorage = JSON.parse(localStorage.getItem('infosperso'));
        const userDetails: KeycloakTokenParsed | undefined = { ...this.keycloakService.getKeycloakInstance().idTokenParsed };
        const isErrorExist=JSON.parse(localStorage.getItem('infospersoError'));
        if  ((this.infosStorage!=null) && (userDetails.roles[1]=='offline_access') && (isErrorExist==undefined||isErrorExist==null) && (localStorage.getItem('firstConnect')==null)) {//affiche modal si type adherent différent de 'employeur' & 'si c'est la premiere connexion'
          localStorage.setItem('firstConnect','true');
          this.showModal(); //lancement de la modal de bienvenue
        }
      }
    );
   }

   getCards():string{
    return this.numberOfCards;
   }

   ngOnInit(): void {
    this.langService.init();
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.initializeSubscription = this.ccService.initialize$.subscribe(
      (event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
  }

  public getUser(): void {
    if (JSON.parse(localStorage.getItem('loggedUser'))) {
      this.currentUser = JSON.parse(localStorage.getItem('loggedUser'));
    } else {
      this.authService.getLoggerUser().then((value: any) => {
        this.currentUser = value;
      });
    }
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
    this.authService.onDataReady.unsubscribe();
  }

  
  /******************************************MODAL WELCOME****************************************************/
  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal?.hide();
    localStorage.setItem('firstConnect','true');
  }

  onHidden(): void {
    this.isModalShown = false;
    localStorage.setItem('firstConnect','true');
  }
}
