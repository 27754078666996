export const kinds = {
  USER_NOT_FOUND: `Aucun utilisateur trouvé !`,
};

export const errorbase = {
  '00': `Ok`,
  '01': `Erreur middelware`,
  '02': `Profil d’authentification ne doit pas être administrateur`,
  50: `Le n° Cafat est vide dans le jeton`,
  51: `N° Cafat inexistant, connexion impossible `,
  52: `Aucune adhésion mpl pour cet assuré, connexion impossible`,
  53: `Adhésion inactive depuis plus de 2 ans, connexion impossible`,
};
